
import { defineComponent, ref, onMounted } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import addPosModal from "@/views/sales-channels/point-of-sale/AddPosModal.vue";
import FilterDropdwon from "@/views/sales-channels/point-of-sale/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiPos } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import fileDownload from "js-file-download";
import { formatDateTime, setModuleBCN } from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

interface Pos {
  id: number;
  name: string;
  short_key: string;
  city: string;
  country_iso_2: string;
  merchant: string;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "Pos",
  components: {
    MBDatatable,
    FilterDropdwon,
    addPosModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<Pos>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const sortOptions = ref<Array<SortOption>>([]);
    const disabledExport = ref(false);
    const filterOptions = ref<Array<FilterOption>>([
      {
        field: "status",
        value: "10",
      },
    ]);
    const tableHeader = ref([
      {
        name: "ID",
        key: "id",
        sortable: true,
      },
      {
        name: "POS Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Country",
        key: "country_iso_2",
        sortable: true,
      },
      {
        name: "City",
        key: "city",
        sortable: true,
      },
      {
        name: "Parent Merchant",
        key: "merchant_id",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Creation time",
        key: "created_at",
        sortable: true,
      },
      {
        name: t("common.action"),
        key: "actions",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getPosList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    });

    const getPosList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiPos.getPosList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (
          item == "merchant_id" ||
          item == "country_iso_2" ||
          item == "status"
        ) {
          filterArr.push({
            field: item,
            value: val[item],
            condition: "eq",
          });
        } else if (val[item] != "") {
          filterArr.push({
            field: item,
            value: val[item],
            condition: "like",
          });
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      getPosList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getPosList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getPosList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getPosList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getPosList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const debounceSearch = _.debounce(getPosList, 1000);

    const searchItems = () => {
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const fileExport = () => {
      disabledExport.value = true;
      ApiPos.posPrint({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        download_data_type: "stream",
      })
        .then((data) => {
          disabledExport.value = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          disabledExport.value = false;
          console.log(error);
        });
    };

    const updateLists = () => {
      getPosList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    return {
      formatDateTime,
      loading,
      disabledExport,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      getPosList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      updateLists,
      fileExport,
    };
  },
});
