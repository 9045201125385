
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiMerchant, ApiPos } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";

export default defineComponent({
  name: "add-pos-modal",
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const addPosModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      status: 10,
      name: "",
      short_key: "",
      email: "",
      website: "",
      telephone: "",
      telephone2: "",
      fax: "",
      merchant_id: "",
      address: "",
      zip_code: "",
      city: "",
      country_iso_2: "",
      overall_notes: "",
      store_in_store: 0,
      inter_contact: "",
    });

    const countryOptions = ref([]);
    const parentCompanys = ref([]);
    const rules = ref({
      name: [
        {
          required: true,
          message: "Pos Name is required",
          trigger: "change",
        },
      ],
      short_key: [
        {
          required: true,
          message: "Pos Short-Key is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },
      ],
      telephone: [
        {
          required: true,
          message: "Telephone is required",
          trigger: "change",
        },
      ],
      address: [
        {
          required: true,
          message: "Address is required",
          trigger: "change",
        },
      ],
      zip_code: [
        {
          required: true,
          message: "Zip Code is required",
          trigger: "change",
        },
      ],
      city: [
        {
          required: true,
          message: "City is required",
          trigger: "change",
        },
      ],
      country_iso_2: [
        {
          required: true,
          message: "Country is required",
          trigger: "change",
        },
      ],
      merchant_id: [
        {
          required: true,
          message: "Parent Merchant is required",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiPos.addPos(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addPosModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getCountryOptions = () => {
      ApiBase.getCountryData()
        .then(({ data }) => {
          if (data.code == 0) {
            countryOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      if (data.code == 0) {
        parentCompanys.value = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const handleDiscard = () => {
      hideModal(addPosModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onMounted(() => {
      getCountryOptions();
    });

    return {
      loading,
      formData,
      rules,
      formRef,
      addPosModalRef,
      submitButton,
      countryOptions,
      parentCompanys,
      submit,
      handleDiscard,
      resetForm,
      searchMerchantSourceItems,
    };
  },
});
